import { Box, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { InitProdState, ScoringLevel, subscribeToEvent, unsubscribeFromEvent } from "gx-npm-lib";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { calculateScorecardProgressV2 } from "../../../../context/lib";
import { useScoresheetContext } from "../../../../context/scoresheet.context";
import useRequirements from "../../../../context/use-requirements.hook";
import useScoresheet from "../../../../context/use-scoresheet.hook";
import { CustomEvents } from "../../../../lib/types";
import FaqSection from "./faq-section/faq-section.component";
import ProgressBar from "./progress-bar/progress-bar.component";
import styles from "./scoring-progress-information.styles";
import {
  TypographyComponent,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
} from "gx-npm-ui";
import { GCOM_4463_GCOM_4467__scoresheetProgessRole } from "../../../../lib/feature-flags";

const useStyles = makeStyles(() => styles);
const ScoringProgressInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { categories, scoringLevel, totalCategories } = useRequirements();
  const { isEvaluator, userRole, productState } = useScoresheet();
  const { setProductState } = useScoresheetContext();
  const [assignedCategoryCount, setAssignedCategoryCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isViewer, setIsViewer] = useState(true);
  const isScreenedOut = productState === InitProdState.SCREENED_OUT;

  useEffect(() => {
    const listener = (data: { detail: { productState: InitProdState } }) => setProductState(data.detail.productState);
    subscribeToEvent(CustomEvents.WORKSPACE_HEADER_ACTIONS, listener);
    return () => {
      unsubscribeFromEvent(CustomEvents.WORKSPACE_HEADER_ACTIONS, listener);
    };
  }, [setProductState]);

  useEffect(() => {
    setIsViewer(userRole === "VIEWER" || !isEvaluator);
  }, [isEvaluator, userRole]);

  useEffect(() => {
    if (categories.length > 0) {
      const progress = calculateScorecardProgressV2(scoringLevel, categories);
      setCompletedCount(progress.cnt);
      setTotalCount(progress.total);
      setAssignedCategoryCount(progress.totalCats);
      setIsLoaded(true);
    }
  }, [categories, scoringLevel]);

  useEffect(() => {
    setPercent((completedCount / totalCount) * 100);
  }, [completedCount, totalCount]);

  return (
    <Fragment>
      {isLoaded && (
        <div className={classnames(classes.scoreContainer, isScreenedOut && "screened-out", isViewer && "viewer")}>
          {!isViewer && (
            <Fragment>
              <TypographyComponent rootClassName={classes.title} styling={"p3"} color={"white"} boldness={"medium"}>
                {t("Your scoring assignment")}
              </TypographyComponent>
              <div className={classnames(classes.categoryDetails)}>
                <TypographyComponent
                  rootClassName={classnames(classes.categoryDetails)}
                  styling={"p3"}
                  element={"span"}
                  color={"white"}
                  boldness={"medium"}
                >
                  {assignedCategoryCount} {t("of")} {totalCategories} {t("categories")}{" "}
                  {scoringLevel === ScoringLevel.REQUIREMENT && (
                    <TypographyComponent styling={"p3"} element={"span"} color={"white"} boldness={"regular"}>
                      {`(${totalCount} ${t("requirements")})`}
                    </TypographyComponent>
                  )}
                </TypographyComponent>
              </div>
              <TypographyComponent styling={"p4"} color={"white"}>
                {scoringLevel === ScoringLevel.REQUIREMENT && <>{t("Requirements scored")}</>}
                {scoringLevel === ScoringLevel.CATEGORY && <>{t("Categories scored")}</>}
              </TypographyComponent>

              <Box className={classes.scoreProgressWrapper}>
                <Box>
                  <TypographyComponent boldness={"medium"} color={"white"} styling={"h3"}>
                    {completedCount} / {totalCount}
                  </TypographyComponent>
                </Box>
                <Box className={classes.scoreProgressBar}>
                  <FeatureFlagBooleanContainer flagName={GCOM_4463_GCOM_4467__scoresheetProgessRole}>
                    <FeatureFlagBooleanOff>
                      <ProgressBar percent={percent} disabled={isScreenedOut} progressLabel={""} />
                    </FeatureFlagBooleanOff>
                    <FeatureFlagBooleanOn>
                      <ProgressBar
                        percent={percent}
                        disabled={isScreenedOut}
                        progressLabel={completedCount + " " + t("out of") + " " + totalCount + " " + t("scored")}
                      />
                    </FeatureFlagBooleanOn>
                  </FeatureFlagBooleanContainer>
                </Box>
              </Box>
            </Fragment>
          )}
          <FaqSection isViewer={isViewer} disabled={isScreenedOut} />
        </div>
      )}
    </Fragment>
  );
};

export default ScoringProgressInformation;
