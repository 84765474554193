import React from "react";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { colorPalette } from "gx-npm-common-styles";

type ProgressBarProps = {
  percent: number;
  disabled: boolean;
  progressLabel: string;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ percent, disabled, progressLabel }) => {
  const colorSweetHuckleberry = colorPalette.accent.sweetHuckleberry.hex;
  const colorSourKiwi = colorPalette.accent.sourKiwi.hex;
  const colorIron = colorPalette.neutrals.iron.hex;
  const colorQuartz = colorPalette.neutrals.quartz.hex;

  const barColor = disabled ? colorIron : colorSourKiwi;
  const backgroundBarColor = disabled ? colorQuartz : colorSweetHuckleberry;

  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 8,
      borderRadius: 5,
      border: "1px solid " + backgroundBarColor,
    },
    colorPrimary: {
      backgroundColor: backgroundBarColor,
    },
    bar: {
      borderRadius: 5,
      backgroundColor: barColor,
    },
  }))(LinearProgress);

  return <BorderLinearProgress variant="determinate" value={percent} aria-label={progressLabel} />;
};

export default ProgressBar;
