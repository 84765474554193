import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { MessageEmptyIcon, MessageFilledIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { IconButton, TooltipV2 } from "gx-npm-ui";
import { messageIconStyles as styles } from "./styles";
import React from "react";

type MessageIconWithToolTipProps = {
  handleClick: () => void;
  hasComments: boolean;
  isOpen: boolean;
  isViewOnly?: boolean;
};

const useStyles = makeStyles(() => styles);
const MessageIconWithToolTip: React.FC<MessageIconWithToolTipProps> = ({
  handleClick,
  hasComments,
  isOpen,
  isViewOnly = false,
}) => {
  const { t } = useTranslation();
  let tooltipText = t("Click to close comments");
  let ariaLabelForIconButton = t("close comment");
  if (!isOpen) {
    tooltipText = hasComments ? t("Click to view comments") : t("Click to add comments");
    ariaLabelForIconButton = hasComments ? t("view comment") : t("add comment");
  }
  const classes = useStyles();
  return (
    <TooltipV2
      placement="right"
      deactivate={isViewOnly}
      title={tooltipText}
      PopperProps={{ modifiers: { offset: { offset: "0px, 10px" } } }}
      enterDelay={400}
      enterNextDelay={400}
      rootClassName={"gx-survey-message-tool-tip"}
    >
      <div className={classes.messageIconWrapper}>
        <IconButton onClick={() => handleEvent(handleClick)} ariaLabel={ariaLabelForIconButton}>
          {hasComments && <MessageFilledIcon title="" />}
          {!hasComments && <MessageEmptyIcon title="" />}
        </IconButton>
      </div>
    </TooltipV2>
  );
};

export { MessageIconWithToolTip };
