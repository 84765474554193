// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GKu5w6thKLjTIMOOlTpv{padding-right:81px}.GKu5w6thKLjTIMOOlTpv .OsTn3wpa5OXf_2_hQGUR{align-items:center;display:flex;gap:15px;padding-bottom:12px}.GKu5w6thKLjTIMOOlTpv ._QBPe0CVFxT_qcWwbABb{width:400px}.GKu5w6thKLjTIMOOlTpv .gUhnliEdTJDJlzofFlcB{padding-left:12px;width:388px}.GKu5w6thKLjTIMOOlTpv .gUhnliEdTJDJlzofFlcB .wbblZd6BMIxywqkwIAw9{margin-bottom:12px}.GKu5w6thKLjTIMOOlTpv .gUhnliEdTJDJlzofFlcB .wbblZd6BMIxywqkwIAw9::marker{color:var(--color-white)}.GKu5w6thKLjTIMOOlTpv .KY4wMHcapxKHTYT5iwxT{background-color:var(--color-white);height:1px;opacity:.2;margin-bottom:12px;margin-top:32px}.GKu5w6thKLjTIMOOlTpv .gIptBUDUP1HpE5cduZz3{margin-bottom:16px}.GKu5w6thKLjTIMOOlTpv .lr2_UNVINIoBmKzGA6rG{width:320px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/ribbon/research-slide-in-v2/research-section/research-section.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAEA,4CACE,kBAAA,CACA,YAAA,CACA,QAAA,CACA,mBAAA,CAGF,4CACE,WAAA,CAGF,4CACE,iBAAA,CACA,WAAA,CAEA,kEACE,kBAAA,CAEA,0EACE,wBAAA,CAKN,4CACE,mCAAA,CACA,UAAA,CACA,UAAA,CACA,kBAAA,CACA,eAAA,CAGF,4CACE,kBAAA,CAGF,4CACE,WAAA","sourcesContent":[".root {\n  padding-right: 81px;\n\n  & .header {\n    align-items: center;\n    display: flex;\n    gap: 15px;\n    padding-bottom: 12px;\n  }\n\n  & .description {\n    width: 400px;\n  }\n\n  & .list {\n    padding-left: 12px;\n    width: 388px;\n\n    & .listItem {\n      margin-bottom: 12px;\n\n      &::marker {\n        color: var(--color-white);\n      }\n    }\n  }\n\n  & .divider {\n    background-color: var(--color-white);\n    height: 1px;\n    opacity: 0.2;\n    margin-bottom: 12px;\n    margin-top: 32px;\n  }\n\n  & .learnMore {\n    margin-bottom: 16px;\n  }\n\n  & .linkText {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GKu5w6thKLjTIMOOlTpv`,
	"header": `OsTn3wpa5OXf_2_hQGUR`,
	"description": `_QBPe0CVFxT_qcWwbABb`,
	"list": `gUhnliEdTJDJlzofFlcB`,
	"listItem": `wbblZd6BMIxywqkwIAw9`,
	"divider": `KY4wMHcapxKHTYT5iwxT`,
	"learnMore": `gIptBUDUP1HpE5cduZz3`,
	"linkText": `lr2_UNVINIoBmKzGA6rG`
};
export default ___CSS_LOADER_EXPORT___;
